export const getYears = () => {
  let years = []
  let year = new Date().getFullYear()
  while (year > 1990) {
    years.push(String(year))
    year -= 1
  }
  return years
}

export const getLevelList = (type) => {
  let data = []
  if (type === '管理岗位') {
    data = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十']
  } else if (type === '专业技术岗位') {
    data = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二', '十三']
  } else if (type === '工勤技能岗位') {
    data = ['一', '二', '三', '四', '五', '普通工']
  }
  return data
}


export const getTitleByConfig = (config) => {
  let titleList = []
  config.lineGroupList.forEach(v => titleList.push(v.name))
  config.rowGroupList.forEach(v => titleList.push(v.name))
  return titleList.join(' ')
}

export const getCountConfigList = ({lineGroupKeys, rowGroupKeys}) => {
  let configList = []
  let i = 0
  while (i < lineGroupKeys.length) {
    let j = 0
    while (j < rowGroupKeys.length) {
      configList.push(getCountConfigItem({
        lineGroupKey: lineGroupKeys[i],
        rowGroupKey: rowGroupKeys[j]
      }))
      j += 1
    }
    i += 1
  }
  return configList
}

export const countWorkerColumns = [{
  title: '姓名',
  sort: true,
  field: 'name',
  fixed: 'left'
},
{
  title: '电话',
  sort: true,
  field: 'phone'
},
{
  title: '性别',
  sort: true,
  field: 'sex'
},
{
  title: '年龄',
  sort: true,
  field: 'age'
},
{
  title: '民族',
  sort: true,
  field: 'clan'
},
{
  title: '政治面貌',
  sort: true,
  field: 'politicalStatus'
},
{
  title: '是否是残疾人',
  sort: true,
  field: 'isDisabled',
  render: (h, rowData) => {
    return h('div', rowData.isDisabled === null ? '-' : (rowData.isDisabled ? '是' : '否'))
  }
},
{
  title: '是否是运动员',
  sort: true,
  field: 'isAthlete',
  render: (h, rowData) => {
    return h('div', rowData.isAthlete === null ? '-' : (rowData.isAthlete ? '是' : '否'))
  }
},
{
  title: '是否是港澳居民',
  sort: true,
  field: 'isGajm',
  render: (h, rowData) => {
    return h('div', rowData.isGajm === null ? '-' : (rowData.isGajm ? '是' : '否'))
  }
},
{
  title: '是否是台湾同胞',
  sort: true,
  field: 'isTwtb',
  render: (h, rowData) => {
    return h('div', rowData.isTwtb === null ? '-' : (rowData.isTwtb ? '是' : '否'))
  }
},
{
  title: '是否是外籍人士',
  sort: true,
  field: 'isWjrs',
  render: (h, rowData) => {
    return h('div', rowData.isWjrs === null ? '-' : (rowData.isWjrs ? '是' : '否'))
  }
},
{
  title: '是否是留学回国人士',
  sort: true,
  field: 'isLxgg',
  render: (h, rowData) => {
    return h('div', rowData.isLxgg === null ? '-' : (rowData.isLxgg ? '是' : '否'))
  }
},
{
  title: '学历',
  sort: true,
  field: 'education'
},
{
  title: '学位',
  sort: true,
  field: 'degree'
},
{
  title: '是否在编',
  fixed: 'right',
  sort: true,
  field: 'isZb',
  render: (h, rowData) => {
    return h('div', rowData.isZb === null ? '-' : (rowData.isZb ? '是' : '否'))
  }
},
{
  title: '是否劳务派遣人员',
  sort: true,
  field: 'isNwpq',
  render: (h, rowData) => {
    return h('div', rowData.isNwpq === null ? '-' : (rowData.isNwpq ? '是' : '否'))
  }
},
{
  title: '是否编外聘用人员',
  sort: true,
  field: 'isBwpy',
  render: (h, rowData) => {
    return h('div', rowData.isBwpy === null ? '-' : (rowData.isBwpy ? '是' : '否'))
  }
},
{
  title: '是否专业技术人员',
  sort: true,
  field: 'isZyjs',
  render: (h, rowData) => {
    return h('div', rowData.isZyjs === null ? '-' : (rowData.isZyjs ? '是' : '否'))
  }
},
{
  title: '专业技术人员专业大类',
  sort: true,
  field: 'bigProType'
},
{
  title: '是否是卫生',
  sort: true,
  field: 'isWs',
  render: (h, rowData) => {
    return h('div', rowData.isWs === null ? '-' : (rowData.isWs ? '是' : '否'))
  }
},
{
  title: '是否是卫生和社会工作',
  sort: true,
  field: 'isWshshgz',
  render: (h, rowData) => {
    return h('div', rowData.isWshshgz === null ? '-' : (rowData.isWshshgz ? '是' : '否'))
  }
},
{
  title: '是否特设岗位人员',
  sort: true,
  field: 'isTgts',
  render: (h, rowData) => {
    return h('div', rowData.isTgts === null ? '-' : (rowData.isTgts ? '是' : '否'))
  }
},
{
  title: '是否具有职业资格',
  sort: true,
  field: 'haveZyzg',
  render: (h, rowData) => {
    return h('div', rowData.haveZyzg === null ? '-' : (rowData.haveZyzg ? '是' : '否'))
  }
},
{
  title: '是否离职',
  sort: true,
  field: 'isDiscipline',
  render: (h, rowData) => {
    return h('div', rowData.isDiscipline === null ? '-' : (rowData.isDiscipline ? '是' : '否'))
  }
},
{
  title: '进入本单位时间',
  sort: true,
  field: 'correctionDate',
  render: (h, rowData) => {
    return h('div', rowData.correctionDate ? rowData.correctionDate.slice(0, 10)  : '-')
  }
},
{
  title: '所属层级',
  sort: true,
  field: 'level'
},
{
  title: '岗位信息',
  fixed: 'right',
  width: 160,
  sort: true,
  field: 'countWorkerJobTitleList',
  render: (h, rowData) => {
    let text = []
    rowData.countWorkerJobTitleList.forEach(v => {
      text.push(v.type + ' ' + v.level)
    })
    return h('div', text.join(','))
  }
}]

export const getCountData = (dataList, {lineGroupList, rowGroupList}) => {
  let countData = []
  lineGroupList.forEach(lineGroup => {
    let lineGroupData = dataList.filter(dataItem => !lineGroup.condition || lineGroup.condition(dataItem))
    lineGroup.fields.forEach(field => {
      let lineData = lineGroupData.filter(dataItem => !field.condition || field.condition(dataItem))
      let countDataItem = {
        __lineLabel: field.label,
        __lineLabel_condition: [lineGroup.condition, field.condition],
        __count: lineData.length,
        __count_condition: [lineGroup.condition, field.condition],
      }
      rowGroupList.forEach(rowGroup => {
        let rowGroupData = lineData.filter(dataItem => !rowGroup.condition || rowGroup.condition(dataItem))
        rowGroup.fields.forEach(rowField => {
          countDataItem[rowField.relatedKeys.join('_')] = rowGroupData.filter(dataItem => !rowField.condition || rowField.condition(dataItem)).length
          countDataItem[rowField.relatedKeys.join('_') + '_condition'] = [lineGroup.condition, field.condition, rowGroup.condition, rowField.condition]
          countDataItem[rowField.relatedKeys.join('_') + '_relatedKeys'] = field.relatedKeys.concat(rowField.relatedKeys).concat(lineGroup.relatedKeys)
        })
      })
      countData.push(countDataItem)
    })
  })
  return countData
}

export const getCountConfigItem = ({lineGroupKey, rowGroupKey}) => {
  let lineGroupList = []
  if (lineGroupKey instanceof Array) {
    lineGroupKey.forEach((v) => {
      lineGroupList.push(groupMap[v])
    })
  } else {
    lineGroupList = [groupMap[lineGroupKey]]
  }

  let rowGroupList = []
  if (rowGroupKey instanceof Array) {
    rowGroupKey.forEach((v) => {
      rowGroupList.push(groupMap[v])
    })
  } else {
    rowGroupList = [groupMap[rowGroupKey]]
  }

  let configItem = {
    lineGroupKey: lineGroupKey,
    rowGroupKey: rowGroupKey,
    lineGroupList: lineGroupList,
    rowGroupList: rowGroupList
  }
  configItem.title = getTitleByConfig(configItem)
  return configItem
}

// 避免key重复
const dealGroupMapBase = (dataMap) => {
  Object.keys(dataMap).forEach(key => {
    let relatedKeys = dataMap[key].relatedKeys || []
    Object.keys(dataMap[key]).forEach(key1 => {
      if (key1 === 'fields') {
        let newRelatedKeys = relatedKeys.map(v => v)
        newRelatedKeys.push('_' + key)
        dataMap[key][key1].forEach(v => v.relatedKeys = (v.relatedKeys || []).concat(newRelatedKeys))
      }
    })
  })
  return dataMap
}


export const groupMapBase = dealGroupMapBase({
  base: {
    name: '基础信息',
    fields: [{
      label: '女',
      relatedKeys: ['sex'],
      condition (v) {
        return v.sex === '女'
      }
    },
    {
      label: '少数民族',
      relatedKeys: ['clan'],
      condition (v) {
        return v.clan && v.clan !== '汉族'
      }
    },
    {
      label: '中共党员',
      relatedKeys: ['politicalStatus'],
      condition (v) {
        return v.politicalStatus === '中共党员'
      }
    },
    {
      label: '残疾人',
      relatedKeys: ['isDisabled'],
      condition (v) {
        return v.isDisabled
      }
    },
    {
      label: '港澳居民',
      relatedKeys: ['isGajm'],
      condition (v) {
        return v.isGajm
      }
    },
    {
      label: '台湾同胞',
      relatedKeys: ['isTwtb'],
      condition (v) {
        return v.isTwtb
      }
    },
    {
      label: '外籍人士',
      relatedKeys: ['isWjrs'],
      condition (v) {
        return v.isWjrs
      }
    },
    {
      label: '留学回国人士',
      relatedKeys: ['isLxgg'],
      condition (v) {
        return v.isLxgg
      }
    },
    {
      label: '卫生',
      relatedKeys: ['isWs'],
      condition (v) {
        return v.isWs
      }
    },
    {
      label: '劳务派遣人员',
      relatedKeys: ['isNwpq'],
      condition (v) {
        return v.isNwpq
      }
    },
    {
      label: '编外聘用人员',
      relatedKeys: ['isBwpy'],
      condition (v) {
        return v.isBwpy
      }
    }]
  },
  education: {
    name: '学历信息',
    relatedKeys: ['education'],
    fields: [{
      label: '研究生',
      relatedKeys: ['yjs'],
      condition (v) {
        return v.education === '研究生'
      }
    },
    {
      label: '大学本科',
      relatedKeys: ['dxbk'],
      condition (v) {
        return v.education === '大学本科'
      }
    },
    {
      label: '大学专科',
      relatedKeys: ['dxzk'],
      condition (v) {
        return v.education === '大学专科'
      }
    },
    {
      label: '中专',
      relatedKeys: ['zz'],
      condition (v) {
        return v.education === '中专'
      }
    },
    {
      label: '高中及以下',
      relatedKeys: ['gzjyx'],
      condition (v) {
        return ['高中', '初中', '小学'].includes(v.education)
      }
    }]
  },
  degree: {
    name: '学位信息',
    relatedKeys: ['degree'],
    fields: [{
      label: '博士',
      relatedKeys: ['degree', 'bs'],
      condition (v) {
        return v.degree === '博士'
      }
    },
    {
      label: '硕士',
      relatedKeys: ['degree', 'ss'],
      condition (v) {
        return v.degree === '硕士'
      }
    },
    {
      label: '学士',
      relatedKeys: ['degree', 'xs'],
      condition (v) {
        return v.degree === '学士'
      }
    }]
  },
  age: {
    name: '年龄',
    relatedKeys: ['age'],
    fields: [{
      label: '35岁及以下',
      relatedKeys: ['35'],
      condition (v) {
        return v.age && v.age < 36
      }
    },
    {
      label: '36岁至40岁',
      relatedKeys: ['40'],
      condition (v) {
        return v.age && v.age > 35 && v.age < 41
      }
    },
    {
      label: '41岁至45岁',
      relatedKeys: ['45'],
      condition (v) {
        return v.age && v.age > 40 && v.age < 46
      }
    },
    {
      label: '46岁至50岁',
      relatedKeys: ['50'],
      condition (v) {
        return v.age && v.age > 45 && v.age < 51
      }
    },
    {
      label: '51岁至54岁',
      relatedKeys: ['54'],
      condition (v) {
        return v.age && v.age > 50 && v.age < 55
      }
    },
    {
      label: '55岁至59岁',
      relatedKeys: ['59'],
      condition (v) {
        return v.age && v.age > 54 && v.age < 60
      }
    },
    {
      label: '60岁及以上',
      relatedKeys: ['60'],
      condition (v) {
        return v.age && v.age > 59
      }
    }]
  },
  level: {
    name: '所属层级',
    relatedKeys: ['level'],
    fields: [{
      label: '中央',
      relatedKeys: ['zy'],
      condition (v) {
        return v.level === '中央'
      }
    },
    {
      label: '省（区、市）',
      relatedKeys: ['sqs'],
      condition (v) {
        return v.level === '省（区、市）'
      }
    },
    {
      label: '地（市、州、盟）',
      relatedKeys: ['dszm'],
      condition (v) {
        return v.level === '地（市、州、盟）'
      }
    },
    {
      label: '县（市、区、旗）',
      relatedKeys: ['xsqq'],
      condition (v) {
        return v.level === '县（市、区、旗）'
      }
    },
    {
      label: '乡（镇）',
      relatedKeys: ['xz'],
      condition (v) {
        return v.level === '乡（镇）'
      }
    }]
  },
  glgw_level: {
    name: '管理岗位',
    relatedKeys: ['countWorkerJobTitleList'],
    condition (v) {
      return jobTitleCondition(v, '管理岗位')
    },
    fields: [{
      label: '小计',
      relatedKeys: ['_xj']
    }].concat(getLevelList('管理岗位').map(level => {
      return {
        label: level,
        condition (v) {
          return jobTitleCondition(v, '管理岗位', level)
        }
      }
    }))
  },
  glgw_base: {
    name: '管理岗位(基础)',
    relatedKeys: ['countWorkerJobTitleList'],
    condition (v) {
      return jobTitleCondition(v, '管理岗位')
    },
    fields: [{
      label: '小计',
      relatedKeys: ['_xj']
    },
    {
      label: '女',
      relatedKeys: ['sex'],
      condition (v) {
        return v.sex === '女'
      }
    },
    {
      label: '少数民族',
      relatedKeys: ['clan'],
      condition (v) {
        return v.clan && v.clan !== '汉族'
      }
    },
    {
      label: '残疾人',
      relatedKeys: ['isDisabled'],
      condition (v) {
        return v.isDisabled
      }
    },
    {
      label: '卫生和社会工作',
      relatedKeys: ['isWshshgz'],
      condition (v) {
        return v.isWshshgz
      }
    },
    {
      label: '卫生',
      relatedKeys: ['isWs'],
      condition (v) {
        return v.isWs
      }
    }]
  },
  zyjsg_level: {
    name: '专业技术岗位',
    relatedKeys: ['countWorkerJobTitleList'],
    condition (v) {
      return jobTitleCondition(v, '专业技术岗位')
    },
    fields: [{
      label: '小计',
      relatedKeys: ['_xj']
    }].concat(getLevelList('专业技术岗位').map(level => {
      return {
        label: level,
        condition (v) {
          return jobTitleCondition(v, '专业技术岗位', level)
        }
      }
    }))
  },
  zyjsg_base: {
    name: '专业技术人员(基础)',
    relatedKeys: ['countWorkerJobTitleList', 'isZb', 'isZyjs'],
    condition (v) {
      return v.isZyjs
    },
    fields: [{
      label: '小计',
      relatedKeys: ['_xj']
    },
    {
      label: '在管理岗位工作的',
      relatedKeys: ['countWorkerJobTitleList'],
      condition (v) {
        return jobTitleCondition(v, '管理岗位')
      }
    },
    {
      label: '具有职业资格的',
      relatedKeys: ['haveZyzg'],
      condition (v) {
        return v.haveZyzg
      }
    },
    {
      label: '女',
      relatedKeys: ['sex'],
      condition (v) {
        return v.sex === '女'
      }
    },
    {
      label: '少数民族',
      relatedKeys: ['clan'],
      condition (v) {
        return v.clan && v.clan !== '汉族'
      }
    },
    {
      label: '残疾人',
      relatedKeys: ['isDisabled'],
      condition (v) {
        return v.isDisabled
      }
    },
    {
      label: '卫生和社会工作',
      relatedKeys: ['isWshshgz'],
      condition (v) {
        return v.isWshshgz
      }
    },
    {
      label: '卫生',
      relatedKeys: ['isWs'],
      condition (v) {
        return v.isWs
      }
    }]
  },
  zyjsg_zyjs: {
    name: '专业技术人员(类型)',
    relatedKeys: ['bigProType', 'isZyjs', 'isZb'],
    condition (v) {
      return v.isZyjs && v.isZb && v.bigProType && true
    },
    fields: [{
      label: '小计',
      relatedKeys: ['_xj']
    },
    {
      label: '工程技术人员',
      condition (v) {
        return v.bigProType === '工程技术人员'
      }
    },
    {
      label: '卫生技术人员',
      condition (v) {
        return v.bigProType === '卫生技术人员'
      }
    },
    {
      label: '会计人员',
      condition (v) {
        return v.bigProType === '会计人员'
      }
    },
    {
      label: '其他人员',
      condition (v) {
        return v.bigProType === '其他人员'
      }
    }]
  },
  gqjn_level: {
    name: '工勤技能岗位',
    relatedKeys: ['countWorkerJobTitleList'],
    condition (v) {
      return jobTitleCondition(v, '工勤技能岗位')
    },
    fields: [{
      label: '小计',
      relatedKeys: ['_xj']
    }].concat(getLevelList('工勤技能岗位').map(level => {
      return {
        label: level,
        condition (v) {
          return jobTitleCondition(v, '工勤技能岗位', level)
        }
      }
    }))
  },
  gqjn_base: {
    name: '工勤技能岗位(基础)',
    relatedKeys: ['countWorkerJobTitleList'],
    condition (v) {
      return jobTitleCondition(v, '工勤技能岗位')
    },
    fields: [{
      label: '小计',
      relatedKeys: ['_xj']
    },
    {
      label: '女',
      relatedKeys: ['sex'],
      condition (v) {
        return v.sex === '女'
      }
    },
    {
      label: '少数民族',
      relatedKeys: ['clan'],
      condition (v) {
        return v.clan && v.clan !== '汉族'
      }
    },
    {
      label: '残疾人',
      relatedKeys: ['isDisabled'],
      condition (v) {
        return v.isDisabled
      }
    },
    {
      label: '卫生和社会工作',
      relatedKeys: ['isWshshgz'],
      condition (v) {
        return v.isWshshgz
      }
    },
    {
      label: '特设岗位人员',
      relatedKeys: ['isTgts'],
      condition (v) {
        return v.isTgts
      }
    },
    {
      label: '运动员',
      relatedKeys: ['isAthlete'],
      condition (v) {
        return v.isAthlete
      }
    },
    {
      label: '卫生',
      relatedKeys: ['isWs'],
      condition (v) {
        return v.isWs
      }
    }]
  },
  base_ts: {
    name: '基础信息(分组1)',
    fields: [{
      label: '小计',
      relatedKeys: ['_xj']
    },
    {
      label: '特设岗位人员',
      relatedKeys: ['isTgts'],
      condition (v) {
        return v.isTgts
      }
    },
    {
      label: '运动员',
      relatedKeys: ['isAthlete'],
      condition (v) {
        return v.isAthlete
      }
    },
    {
      label: '卫生',
      relatedKeys: ['isWs'],
      condition (v) {
        return v.isWs
      }
    },
    {
      label: '编外聘用人员',
      relatedKeys: ['isBwpy'],
      condition (v) {
        return v.isBwpy
      }
    },
    {
      label: '劳务派遣人员',
      relatedKeys: ['isNwpq'],
      condition (v) {
        return v.isNwpq
      }
    }]
  },
  agreement: {
    // name: '聘用合同订立情况',
    name: '合同',
    relatedKeys: ['isZb', 'isBwpy' ,'correctionDate'],
    condition (v) {
      return v.entryYear != null && (v.entryYear > 0 || v.entryYear === 0)
    },
    fields: [{
      label: '小计',
      relatedKeys: ['_xj']
    },
    {
      label: '正式在册工作人员',
      relatedKeys: ['isZb', 'zszcry'],
      condition (v) {
        return v.isZb
      }
    },
    {
      label: '上年末已订立聘用合同至今存续-3年以下',
      relatedKeys: ['sn-1', 'correctionDate'],
      condition (v) {
        return v.isZb && v.entryYear != null && (v.entryYear > 1 || v.entryYear === 1) && v.entryYear < 3
      }
    },
    {
      label: '上年末已订立聘用合同至今存续-3年以上',
      relatedKeys: ['sn-2'],
      condition (v) {
        return v.isZb && v.entryYear != null && (v.entryYear > 4 || v.entryYear === 4) && v.entryYear < 8
      }
    },
    {
      label: '上年末已订立聘用合同至今存续-聘用至退休',
      relatedKeys: ['sn-3'],
      condition (v) {
        return v.isZb && v.entryYear != null && (v.entryYear > 9 || v.entryYear === 9)
      }
    },
    {
      label: '本年度新订立聘用合同至今存续-3年以下',
      relatedKeys: ['dn-1'],
      condition (v) {
        return v.isZb && v.entryYear != null && (v.entryYear > 0 || v.entryYear === 0) && v.entryYear < 1
      }
    },
    {
      label: '本年度新订立聘用合同至今存续-3年以上',
      relatedKeys: ['dn-2'],
      condition (v) {
        return v.isZb && v.entryYear != null && (v.entryYear > 3 || v.entryYear === 3) && v.entryYear < 4
      }
    },
    {
      label: '本年度新订立聘用合同至今存续-聘用至退休',
      relatedKeys: ['dn-3'],
      condition (v) {
        return v.isZb && v.entryYear != null && (v.entryYear > 8 || v.entryYear === 8) && v.entryYear < 9
      }
    },
    {
      label: '编外聘用人员',
      relatedKeys: ['isBwpy'],
      condition (v) {
        return v.isBwpy
      }
    },
    {
      label: '订立劳动合同',
      relatedKeys: ['isBwpy', 'dlndht'],
      condition (v) {
        return v.isBwpy
      }
    }]
  },
  train: {
    name: '培训',
    relatedKeys: ['countWorkerJobTitleList', 'isZyjs'],
    // name: '培训情况',
    condition (v) {
      return (jobTitleCondition(v, '管理岗位') || jobTitleCondition(v, '工勤技能岗位') || v.isZyjs) && true
    },
    fields: [{
      label: '小计',
      relatedKeys: ['_xj']
    },
    {
      label: '管理人员-参加事业单位工作人员培训人员数合计',
      relatedKeys: ['countWorkerJobTitleList', 'px_gl1'],
      condition (v) {
        return jobTitleCondition(v, '管理岗位')
      }
    },
    {
      label: '管理人员-90学时（不含）以内',
      relatedKeys: ['countWorkerJobTitleList', 'px_gl2'],
      condition (v) {
        return jobTitleCondition(v, '管理岗位')
      }
    },
    {
      label: '管理人员-参加事业单位工作人员培训人次数合计',
      relatedKeys: ['countWorkerJobTitleList', 'px_gl3'],
      condition (v) {
        return jobTitleCondition(v, '管理岗位')
      }
    },
    {
      label: '管理人员-在岗培训人次',
      relatedKeys: ['countWorkerJobTitleList', 'px_gl4'],
      condition (v) {
        return jobTitleCondition(v, '管理岗位')
      }
    },
    {
      label: '专业技术人员-参加事业单位工作人员培训人员数合计',
      relatedKeys: ['isZyjs', 'px_zyjs1'],
      condition (v) {
        return v.isZyjs
      }
    },
    {
      label: '专业技术人员-90学时（不含）以内',
      relatedKeys: ['isZyjs', 'px_zyjs2'],
      condition (v) {
        return v.isZyjs
      }
    },
    {
      label: '专业技术人员-参加事业单位工作人员培训人次数合计',
      relatedKeys: ['isZyjs', 'px_zyjs3'],
      condition (v) {
        return v.isZyjs
      }
    },
    {
      label: '专业技术人员-在岗培训人次',
      relatedKeys: ['isZyjs', 'px_zyjs4'],
      condition (v) {
        return v.isZyjs
      }
    },
    {
      label: '工勤技能人员-参加事业单位工作人员培训人员数合计',
      relatedKeys: ['countWorkerJobTitleList', 'px_gqjl1'],
      condition (v) {
        return jobTitleCondition(v, '工勤技能岗位')
      }
    },
    {
      label: '工勤技能人员-90学时（不含）以内',
      relatedKeys: ['countWorkerJobTitleList', 'px_gqjl2'],
      condition (v) {
        return jobTitleCondition(v, '工勤技能岗位')
      }
    },
    {
      label: '工勤技能人员-参加事业单位工作人员培训人次数合计',
      relatedKeys: ['countWorkerJobTitleList', 'px_gqjl3'],
      condition (v) {
        return jobTitleCondition(v, '工勤技能岗位')
      }
    },
    {
      label: '工勤技能人员-在岗培训人次',
      relatedKeys: ['countWorkerJobTitleList', 'px_gqjl4'],
      condition (v) {
        return jobTitleCondition(v, '工勤技能岗位')
      }
    }]
  }
})

export const groupMap = groupMapBase

const jobTitleCondition = (dataItem, type, level) => {
  return dataItem.countWorkerJobTitleList && dataItem.countWorkerJobTitleList.find((item) => {
    return item.type === type && (!level || item.level === level)
  }) && true
}
